import React from "react";
import {
  MapPinIcon,
  UsersIcon,
  TagIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import BlurImg from "../../images/chiro-recommend.webp";

const ProfileCard = ({ profile, onConnect, onSkip }) => {
  return (
    <div className="glass-card rounded-2xl overflow-hidden w-[100%] mx-auto shadow-lg">
      <div className="relative h-64">
        {/* <BlurImage
          src={profile.imageUrl}
          alt={profile.name}
          className="w-full h-full object-cover"
        /> */}
        <img src={BlurImg} alt="" className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/40" />
        <div className="absolute bottom-0 left-0 right-0 p-6">
          <div className="flex justify-between items-end">
            <div>
              <h2 className="text-2xl font-semibold text-white">
                {profile.name}
              </h2>
              <p className="text-white/90 text-sm mt-1 flex items-center">
                <MapPinIcon className="w-4 h-4 mr-1.5" />
                {profile.location}
              </p>
            </div>
            <div className="flex items-center space-x-1 bg-white/90 backdrop-blur-sm rounded-full py-1 px-2.5">
              <ChartBarIcon className="w-4 h-4 text-primary" />
              <span className="text-xs font-medium">
                {profile.connectionStrength}%
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="mb-4">
          <p className="text-sm font-medium text-foreground">{profile.title}</p>
          <p className="text-sm text-muted-foreground">{profile.company}</p>
        </div>

        <div className="mb-5">
          <p className="text-sm text-foreground/90 leading-relaxed">
            {profile.bio}
          </p>
        </div>

        <div className="mb-5">
          <div className="flex items-center mb-2">
            <UsersIcon className="w-4 h-4 mr-2 text-muted-foreground" />
            <span className="text-xs text-muted-foreground">
              {profile.mutualConnections} mutual connections
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            {profile.tags.map((tag) => (
              <div
                key={tag}
                className="rounded-full bg-secondary px-2.5 py-1 text-xs font-medium text-secondary-foreground flex items-center"
              >
                <TagIcon className="w-3 h-3 mr-1" />
                {tag}
              </div>
            ))}
          </div>
        </div>

        <div className="flex gap-3 mt-6">
          <button
            onClick={() => onSkip(profile.id)}
            className="flex-1 py-2.5 rounded-xl border border-border hover:bg-secondary transition-all duration-250 ease-in-out"
          >
            <span className="text-sm font-medium text-foreground/80">Skip</span>
          </button>
          <button
            onClick={() => onConnect(profile.id)}
            className="flex-1 py-2.5 bg-primary text-white rounded-xl hover:opacity-90 transition-all duration-250 ease-in-out"
          >
            <span className="text-sm font-medium">Connect</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
