import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProfileCard from "./ProfileCard";

const ProfileCarousel = ({ profiles }) => {
  const sliderRef = useRef(null);

  const handleConnect = (id) => {
    toast.success({
      title: "Connection Request Sent!",
      description: "Your connection request has been sent successfully.",
      duration: 3000,
    });
  };

  const handleSkip = (id) => {
    // Skipping logic
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    centerMode: true,
    centerPadding: "120px", // Default for larger screens
    responsive: [
      {
        breakpoint: 767, // <= 767px
        settings: {
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 640, // <= 640px
        settings: {
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <div className="max-w-2xl mx-auto relative">
      <div className="relative profile-slider">
        <Slider ref={sliderRef} {...settings}>
          {profiles.map((profile) => (
            <div key={profile.id}>
              <ProfileCard
                profile={profile}
                onConnect={handleConnect}
                onSkip={handleSkip}
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="mt-4">
        <button
          onClick={() => sliderRef.current.slickPrev()}
          className="absolute top-1/2 -translate-y-1/2 -left-5 w-12 h-12 rounded-full bg-gray-200 flex justify-center items-center"
        >
          <ArrowLeftIcon className="w-5 h-5 text-customGray" />
        </button>
        <button
          onClick={() => sliderRef.current.slickNext()}
          className="absolute top-1/2 -translate-y-1/2 -right-5 w-12 h-12 rounded-full bg-gray-200 flex justify-center items-center"
        >
          <ArrowRightIcon className="w-5 h-5 text-customGray" />
        </button>
      </div>

      {/* <div className="mt-6 text-center">
        <p className="text-sm text-muted-foreground">
          Showing profile 1 of {profiles.length}
        </p>
      </div> */}
    </div>
  );
};

export default ProfileCarousel;
