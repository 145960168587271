export const psychiatricMetaInfo = {
    "assessment and diagnoses":{
        title:"Mental Health Assessments & Diagnoses Near Me",
        description:" Accurate mental health evaluations by experienced professionals. Get a clear diagnosis and a tailored treatment plan."
       },
    "medication management" :{
        title:"Medication Management Near Me",
        description:"Safe and effective medication management for mental health conditions. Work with skilled providers for optimal treatment."
    },
    "psychotherapy and counseling": {
        title:"Psychotherapy & Counseling Near Me",
        description:"Find professional therapy and counseling for emotional well-being. Evidence-based treatments tailored to your needs."
    },
    "treatment planning": {
        title:"Mental Health Treatment Planning Near Me",
        description:"Create a structured treatment plan with expert guidance. Tailored strategies to support long-term mental wellness."
    },
     "crisis interventions" :{
         title:"Mental Health Crisis Interventions Near Me",
         description:"Get urgent support during a mental health crisis. Compassionate care and professional intervention when you need it most."
    },
    "patient and family education" : {
      title:"Mental Health Education Near Me",
      description:" Learn about mental health conditions and treatments. Empower yourself and your loved ones with expert guidance."
    },
    "psychiatric-provider": {
        title:"Psychiatrist Near Me",
        description:"Get expert psychiatric care from licensed providers. Personalized mental health treatment for a balanced and fulfilling life."

    },
    "ketamine therapy": {
        title:"Ketamine-Assissted Therapy Near Me",
        description:"Safe, supervised ketamine therapy for treatment-resistant mental health conditions. Promote healing and emotional stability."

    },
}
