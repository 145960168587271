export const MHCVirtualMetaInfo = {
    "mental health counselor":{
        title:"Virtual Mental Health Counselor",
        description:"Connect with a virtual mental health counselor. Compassionate, professional support for emotional well-being and personal growth."
       },
    "Mental Health Counselor":{
        title:"Virtual Mental Health Counselor",
        description:"Connect with a virtual mental health counselor. Compassionate, professional support for emotional well-being and personal growth."
       },
    "anxiety counseling doctor":{
        title:"Virtual Anxiety Counseling",
        description:"Access virtual anxiety counseling professionals. Compassionate support to manage stress, reduce anxiety, and improve mental wellness."
    },
    "christian counseling doctor" :{
        title:"Virtual Christian Counseling",
        description:"Connect with virtual Christian counseling professionals. Faith-based support for emotional healing, spiritual growth, and personal well-being."
    },
    "couples counseling doctor":{
        title:"Virtual Couples Counseling",
        description:"Find top-rated virtual couples counseling professionals. Strengthen your relationship with expert guidance for better communication and connection."
    },
    "depression counseling doctor":{
        title:"Virtual Depression Counseling",
        description:"Access virtual depression counseling professionals. Compassionate care to navigate challenges, improve mood, and enhance emotional well-being."
    },
    "divorce counseling doctor":{
        title:"Virtual Divorce Counseling",
        description:"Find virtual divorce counseling services. Supportive professionals helping you navigate emotional challenges and embrace new beginnings."
    },
    "emdr doctor":{
        title:"Virtual EMDR Therapy",
        description:"Discover virtual EMDR therapy professionals. Specialized care to process trauma, reduce distress, and promote emotional healing."
    },
    "family counseling doctor":{
        title:"Virtual Family Counseling",
        description:"Access virtual family counseling professionals. Strengthen relationships, resolve conflicts, and foster harmony within your family unit."
    },
    "grief counseling doctor":{
        title:"Virtual Grief Counseling",
        description:"Find virtual grief counseling professionals. Expert support to navigate loss, heal emotionally, and rebuild your sense of peace."
    },
    "infidelity counseling doctor":{
        title:"Infidelity Counseling Near Me",
        description:"Access verified infidelity counseling services. Expert guidance to rebuild trust, improve communication, and heal your relationship."
    },
    "internal family systems doctor":{
        title:"Virtual Infidelity Counseling",
        description:"Access virtual infidelity counseling services. Expert guidance to rebuild trust, improve communication, and heal your relationship."
    },
   "lgbtq counseling doctor":{
        title:"Virtual LGBTQ Counseling",
        description:"Find virtual LGBTQ+ counseling professionals. Inclusive support for mental health, identity exploration, and personal empowerment."
    },
    "marriage counseling doctor":{
       title:"Virtual Marriage Counseling",
       description:"Discover virtual marriage counseling professionals. Strengthen your partnership with expert guidance for improved communication and connection."
    },
    "mens counseling doctor":{
        title:"Virtual Mens Counseling",
        description:"Access virtual men's counseling services. Professional support for personal growth, emotional well-being, and overcoming life challenges."
    },
    "parenting counseling doctor":{
        title:"Virtual Parenting Counseling",
        description:"Find virtual parenting counseling professionals. Gain valuable guidance to navigate challenges and strengthen family relationships effectively."
    },
    "pre marriage counseling doctor":{
        title:"Virtual Pre-Marriage Counseling",
        description:"Find top-rated virtual pre-marriage counseling services. Expert guidance to build a strong foundation for a lasting partnership."
    },
    "psychotherapy doctor":{
        title:"Virtual Psychotherapy",
        description:"Discover trusted psychotherapists offering virtual therapy. Comprehensive mental health support to promote healing, self-awareness, and emotional well-being."
    },
    "ptsd counseling doctor":{
        title:"Virtual PTSD Counseling",
        description:"Find virtual PTSD counseling services. Compassionate support to process trauma, reduce symptoms, and restore emotional balance."
    },
    "relationship counseling doctor":{
        title:"Virtual Relationship Counseling",
        description:"Access trusted relationship counseling professionals offering virtual services. Expert guidance to improve communication, resolve conflicts, and strengthen connections."
    },
    "trauma counseling doctor":{
        title:"Virtual Trauma Counseling",
        description:"Find experienced trauma therapists offering virtual services. Compassionate care to process experiences, foster healing, and regain emotional resilience."
    },
    "womens counseling doctor":{
        title:"Virtual Womens Counseling",
        description:"Access trusted women's counseling professionals offering virtual services. Supportive care for personal growth, emotional well-being, and life transitions."
    },
   "anger management counseling doctor" : {
       title:"Virtual Anger Management Counseling",
       description:"Find expert anger management counseling with a virtual mental health counselor. Effective strategies to foster emotional control."
    }
}