import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const invoices = createApi({
  reducerPath: "invoices",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/admin/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["Invoices"],
  endpoints: (builder) => ({
    getAllInvoices: builder.query({ query: () => "/invoices?perPage=25" }),
    getFilteredInvoices: builder.query({
      query: (body) => getQueryUrl(body),
    }),
    getAllUserInvoices: builder.query({
      query: (body) => getUserQueryUrl(`/users/${body.id}/invoices?`, body),
    }),
  }),
});

export const {
  useGetAllInvoicesQuery,
  useLazyGetAllInvoicesQuery,
  useLazyGetAllUserInvoicesQuery,
  useGetAllUserInvoicesQuery,
  useLazyGetFilteredInvoicesQuery,
} = invoices;

const getQueryUrl = (params) => {
  let query = "/invoices/?";

  if (params.page) {
    query += `page=${params.page}&`;
  }

  if (params.startDate && params.startDate != "Invalid date") {
    query += `start_date=${params.startDate}&`;
  }

  if (params.endDate && params.endDate != "Invalid date") {
    query += `end_date=${params.endDate}&`;
  }

  if (params.status_ids) {
    query += `status_ids=${params.status_ids}&`;
  }

  if (params.perPage) {
    query += `perPage=${params.perPage}&`;
  }else{
    query += `perPage=25&`
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};

const getUserQueryUrl = (baseQuery, params) => {
  let query = baseQuery;

  if (params.page) {
    query += `page=${params.page}&`;
  }
  if (params.perPage) {
    query += `perPage=${params.perPage}&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
