import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedAdmin } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const professionalUserInfo = createApi({
  reducerPath: "professionalUserInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/admin/v2",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedAdmin,
  }),
  tagTypes: ["ProfessionUserInfoType"],
  endpoints: (builder) => ({
    getAllProfessionals: builder.query({
      query: (page = 1) => `/users?page=${page}&perPage=25`,
      providesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUsersInfo" },
      ],
    }),
    getFilteredProfessionals: builder.query({
      query: (body) => getQueryUrl(body),
    }),
    getUserDetails: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
      ],
    }),
    saveAdminUserInfo: builder.mutation({
      query: ({ professionalId, ...info }) => {
        return {
          url: `/users/${professionalId}`,
          method: "POST",
          body: info,
        };
      },
      invalidatesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUsersInfo" },
        { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
      ],
    }),
    saveAdminUserInfoPhoto: builder.mutation({
      query: ({ professionalId, ...info }) => {
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("photo", info.photo);
        return {
          url: `/users/${professionalId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUserInfoPhoto" },
      ],
    }),
    saveAdminProfessionalRegion: builder.mutation({
      query: ({ regionId, ...regionData }) => {
        return {
          url: `/regions/${regionId}`,
          method: "POST",
          body: regionData,
        };
      },
    }),
    saveAdminProfessionalOfficePhoto: builder.mutation({
      query: (arg) => ({
        url: `/users/${arg.user_id}/office-photos`,
        method: "POST",
        body: arg._method == 'DELETE' ? arg :  arg.office_photos,
      }),
    }),
    addAdminProfessionalRegion: builder.mutation({
      query: (body) => {
        return {
          url: "/regions",
          method: "POST",
          body,
        };
      },
    }),
    deleteProfessionalRegion: builder.mutation({
      query: (regionId) => ({
        url: `/regions/${regionId}`,
        method: "DELETE",
      }),
    }),
    assignProfessionalServices: builder.mutation({
      query: (body) => ({
        url: `/users/${body.professionalId}/services`,
        method: "POST",
        body: { services: body.services },
      }),
      invalidatesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
      ],
    }),
    assignProfesions: builder.mutation({
      query: (body) => ({
        url: `/users/${body.professionalId}/professions`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
      ],
    }),
    getProfessionalMetricsData: builder.query({
      query: (body) => `/users/${body.id}/metrics`,
    }),
    deleteProfessional: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "DELETE",
      }),
    }),
    assignTwilioPhoneNumber: builder.mutation({
      query: ({ id, area_code }) => ({
        url: `/users/${id}/assign-twilio-number`,
        method: "POST",
        body: { area_code }, // Sending area_code in the request body
      }),
      invalidatesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
      ],
    }),
    LicenseAndStateUpdate: builder.mutation({
      query: ({ id, params }) => ({
        url: `/users/${id}/virtual-states`,
        method: "POST",
        body:params
      }),
      invalidatesTags: [
        { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
      ],
    })
    // assignTwilioPhoneNumber: builder.mutation({
    //   query: (body) => ({
    //     url: `/users/${body.id}/assign-twilio-number`,
    //     method: "POST",
    //   }),
    //   invalidatesTags: [
    //     { type: "ProfessionUserInfoType", id: "getAdminUserInfo" },
    //   ],
    // }),
  }),
});

export const {
  useGetAllProfessionalsQuery,
  useLazyGetAllProfessionalsQuery,
  useGetUserDetailsQuery,
  useGetFilteredProfessionalsQuery,
  useLazyGetFilteredProfessionalsQuery,
  useLicenseAndStateUpdateMutation,
  useSaveAdminUserInfoMutation,
  useSaveAdminUserInfoPhotoMutation,
  useSaveAdminProfessionalRegionMutation,
  useDeleteProfessionalRegionMutation,
  useAddAdminProfessionalRegionMutation,
  useSaveAdminProfessionalOfficePhotoMutation,
  useAssignProfessionalServicesMutation,
  useAssignProfesionsMutation,
  useGetProfessionalMetricsDataQuery,
  useDeleteProfessionalMutation,
  useAssignTwilioPhoneNumberMutation
} = professionalUserInfo;

const getQueryUrl = (params) => {
  let query = "/users/?";

  if (params.page) {
    query += `page=${params.page}&`;
  }

  if (params.startDate && params.startDate != "Invalid date") {
    query += `start_date=${params.startDate}&`;
  }

  if (params.endDate && params.endDate != "Invalid date") {
    query += `end_date=${params.endDate}&`;
  }

  if (params.status_ids) {
    query += `status_ids=${params.status_ids}&`;
  }

  if(params.credit_renewal == 0 || params.credit_renewal==1)
  {
    query += `credit_renew=${params.credit_renewal}&`;
  }
  if (params.perPage) {
    query += `perPage=${params.perPage}&`;
  }else{
    query += `perPage=25&`;
  }

  // Remove trailing '&' if exists
  query = query.replace(/&$/, "");

  return query;
};
