import React, { useState, useRef } from "react";
import { useEffect } from "react";

const CustomNumberField = ({
  icon: Icon,
  value,
  onChange,
  placeHolder,
  type,
  errorMessage,
  name,
  classes,
  inputClasses,
  disabled = false,
  successIcon: SuccessIcon,
  onKeyDown,
}) => {
  const [inputValue, setInputValue] = useState();
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    if (
      name === "phone" ||
      name === "cell_phone_number" ||
      name === "office_phone_number" ||
      name === "phone_number" || 
      name === "sms_phone"
    ) {
      let formatValue = formatPhoneNumberPosition(value);
      setInputValue(formatValue);
    } else {
      setInputValue(value);
    }
  }, [value]);

  const handleEnter = (e) => {
    if (e.key === "Enter" && onKeyDown) {
      onKeyDown();
    }
  };
  const handleChange = (event) => {
    // Update the cursor position whenever the input value changes
    setCursorPosition(event.target.selectionStart);
    onChange(event);
    // Other logic for handling input change...
  };

  const formatPhoneNumberPosition = (input) => {
    if (input == null) return;
    // Remove non-numeric characters from the input
    const cleaned = input.replace(/\D/g, "");
    // Format the cleaned input as (000) 000-0000
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    let diff = 0;
    if (match) {
      if (cursorPosition === 1 || cursorPosition == 2 || cursorPosition === 3) {
        diff = 1;
      }
      if (cursorPosition >= 7) {
        diff = 4;
      }
      if (cursorPosition === 4 || cursorPosition == 5 || cursorPosition === 6) {
        diff = 3;
      }
      setTimeout(() => {
        inputRef &&
          inputRef.current?.setSelectionRange(
            cursorPosition + diff,
            cursorPosition + diff
          );
      });
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    if (input.length === 9 && input.length !== cursorPosition) {
      if (cursorPosition === 1 || cursorPosition == 2 || cursorPosition === 3) {
        diff = 1;
      }
      if (cursorPosition >= 9) {
        diff = 4;
      }
      if (
        cursorPosition === 7 ||
        cursorPosition === 6 ||
        cursorPosition === 8
      ) {
        diff = 3;
      }
    }
    setTimeout(() => {
      inputRef &&
        inputRef.current?.setSelectionRange(
          cursorPosition - parseInt(diff),
          cursorPosition - parseInt(diff)
        );
    });
    return input;
  };

  return (
    <div>
      <div className="w-full">
        <div className={`${classes}`}>
          {Icon && (
            <span className="pl-[14px] md:pl-[20px] pr-[10px] border-solid border-[#CCD0D4] border-r bg-transparent">
              <Icon className="icon w-6 h-6" />
            </span>
          )}
          <input
            ref={inputRef}
            id={name}
            name={name}
            type={type}
            value={inputValue}
            placeholder={placeHolder}
            className={`${inputClasses}`}
            onChange={(event) => {
              handleChange(event);
            }}
            disabled={disabled}
            onKeyDown={handleEnter}
            maxLength={
              name === "cell_phone_number" ||
              name === "office_phone_number" ||
              name === "phone" ||
              name === "phone_number" || 
              name === "sms_phone"
                ? 14
                : ""
            }
            onKeyPress={(event) => {
              if (
                name === "cell_phone_number" ||
                name === "office_phone_number" ||
                name === "phone" ||
                name === "phone_number" || 
                name === "sms_phone"
              ) {
                const regex = /^[0-9]+$/;
                const key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              } else if (name === "city") {
                const regex = /^[a-zA-Z\s]+$/;
                const key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }
            }}
          />
          {SuccessIcon && (
            <span className="pl-[14px] md:pl-[10px] pr-[20px] border-solid border-[#CCD0D4] border-l bg-transparent">
              <SuccessIcon className="icon w-6 h-6 text-[#16CA7C]" />
            </span>
          )}
        </div>
        {errorMessage && (
          <p className="text-red-500 text-xs leading-[160%] font-bold mb-4 -mt-4 block">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomNumberField;
