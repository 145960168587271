import { configureStore } from "@reduxjs/toolkit";
import profileSlice from "./reducers/profile.reducer";
import questionsSlice from "./reducers/questions.reducer";
import professionalsSlice from "./reducers/professionals.reducer";
import { getTestimonials } from "./testmonials";
import { postLogin } from "./login";
import { getProfessions } from "./professions";
import { getProfessionalMatches } from "./matches";
import { getLeadDetails, saveLeadServices } from "./leadDetails";
import { getCourses } from "./courses";
import { professionalInfo } from "./professionalInfo";
import { getPaymentDetails } from "./paymentDetails";
import { paymentCreation } from "./paymentCreation";
import { getInvoicesDetails } from "./invoices";
import { getInsuranceDetails } from "./insuranceDetails";
import { getLeadQuestions } from "./leadFlow";
import { verifyOtp } from "./otp";
import { templateCreation } from "./templates";
import matchesSlice from "./reducers/matchesSlice";
import { getCreditRefundDetails } from "./creditRefund";
import creditsRefundSlice from "./reducers/creditsRefundSlice";
import coursesSlice from "./reducers/coursesSlice";
import { getNotifications } from "./professionalNotifications";
import notificationsSlice from "./reducers/notificationsSlice";
import { resetPassword } from "./resetPassword";
import { landingPage } from "./landingPage";
import { professionalUserInfo } from "./admin/professionalUserInfo";
import { leads } from "./admin/leads";
import { matchesInfo } from "./admin/matchesInfo";
import { announcements } from "./admin/announcements";
import professionsSlice from "./reducers/professionsSlice";
import { faqs } from "./faqs";
import { creditRequests } from "./admin/creditRequests";
import { userStatuses } from "./admin/userStatuses";
import { adminProfessions } from "./admin/adminProfessions";
import { invoices } from "./admin/invoices";
import { adminMetrics } from "./admin/adminMetrics";
import { professionalAnnouncement } from "./professionalAnnouncement";
import { impersonate } from "./admin/impersonate";
import { impersonateProfessional } from "./admin/impersonateProfessional";
import globalLoaderSlice from "./reducers/globalLoaderSlice";
import { authTwilioChatToken, twilioChatToken } from "./authTwilioToken";
import chatSlice from "./twilio-reducers/chatSlice";
import chatParticipantsSlice from "./twilio-reducers/chatParticipants";
import { adminTemplateCreation } from "./admin/adminTemplate";

export const store = configureStore({
  reducer: {
    globalLoader: globalLoaderSlice,
    profile: profileSlice,
    questions: questionsSlice,
    professionalSignUp: professionalsSlice,
    leadMatches: matchesSlice,
    creditsRefundRequest: creditsRefundSlice,
    coursesSlice: coursesSlice,
    notificationsSlice: notificationsSlice,
    professionsSlice: professionsSlice,
    chat: chatSlice,
    chatParticipants: chatParticipantsSlice,
    [getTestimonials.reducerPath]: getTestimonials.reducer,
    [postLogin.reducerPath]: postLogin.reducer,
    [getProfessions.reducerPath]: getProfessions.reducer,
    [getProfessionalMatches.reducerPath]: getProfessionalMatches.reducer,
    [getLeadDetails.reducerPath]: getLeadDetails.reducer,
    [getCourses.reducerPath]: getCourses.reducer,
    [professionalInfo.reducerPath]: professionalInfo.reducer,
    [getPaymentDetails.reducerPath]: getPaymentDetails.reducer,
    [paymentCreation.reducerPath]: paymentCreation.reducer,
    [getInvoicesDetails.reducerPath]: getInvoicesDetails.reducer,
    [getInsuranceDetails.reducerPath]: getInsuranceDetails.reducer,
    [saveLeadServices.reducerPath]: saveLeadServices.reducer,
    [getLeadQuestions.reducerPath]: getLeadQuestions.reducer,
    [verifyOtp.reducerPath]: verifyOtp.reducer,
    [templateCreation.reducerPath]: templateCreation.reducer,
    [getCreditRefundDetails.reducerPath]: getCreditRefundDetails.reducer,
    [getNotifications.reducerPath]: getNotifications.reducer,
    [resetPassword.reducerPath]: resetPassword.reducer,
    [landingPage.reducerPath]: landingPage.reducer,
    [professionalUserInfo.reducerPath]: professionalUserInfo.reducer,
    [leads.reducerPath]: leads.reducer,
    [matchesInfo.reducerPath]: matchesInfo.reducer,
    [announcements.reducerPath]: announcements.reducer,
    [faqs.reducerPath]: faqs.reducer,
    [creditRequests.reducerPath]: creditRequests.reducer,
    [userStatuses.reducerPath]: userStatuses.reducer,
    [adminProfessions.reducerPath]: adminProfessions.reducer,
    [invoices.reducerPath]: invoices.reducer,
    [adminMetrics.reducerPath]: adminMetrics.reducer,
    [professionalAnnouncement.reducerPath]: professionalAnnouncement.reducer,
    [impersonate.reducerPath]: impersonate.reducer,
    [impersonateProfessional.reducerPath]: impersonateProfessional.reducer,
    [authTwilioChatToken.reducerPath]: authTwilioChatToken.reducer,
    [twilioChatToken.reducerPath]: twilioChatToken.reducer,
    [adminTemplateCreation.reducerPath]:adminTemplateCreation.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      getTestimonials.middleware,
      postLogin.middleware,
      getProfessions.middleware,
      getProfessionalMatches.middleware,
      getLeadDetails.middleware,
      getCourses.middleware,
      professionalInfo.middleware,
      getPaymentDetails.middleware,
      paymentCreation.middleware,
      getInvoicesDetails.middleware,
      getInsuranceDetails.middleware,
      saveLeadServices.middleware,
      getLeadQuestions.middleware,
      verifyOtp.middleware,
      templateCreation.middleware,
      getCreditRefundDetails.middleware,
      getNotifications.middleware,
      resetPassword.middleware,
      landingPage.middleware,
      professionalUserInfo.middleware,
      leads.middleware,
      matchesInfo.middleware,
      announcements.middleware,
      faqs.middleware,
      creditRequests.middleware,
      userStatuses.middleware,
      adminProfessions.middleware,
      invoices.middleware,
      adminMetrics.middleware,
      professionalAnnouncement.middleware,
      impersonate.middleware,
      impersonateProfessional.middleware,
      authTwilioChatToken.middleware,
      twilioChatToken.middleware,
      adminTemplateCreation.middleware
    ),
});
