export const transformLocation = (location) => {
    if (!location) return "";

    const parts = location.split("-");
    if (parts.length < 2) return location.replace(/-/g, ", ");

    const state = parts.pop().toUpperCase(); // Get the last part as the state
    const city = parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()) // Capitalize each part
      .join(" "); // Join the city parts with a space

    return `${city}, ${state}`;
  };


  export const cleanProfessionName = (name) => {
    return name.replace(/[\s\-_]/g, "").split("(")[0].toLowerCase();
  };

  export const searchZipCode = async (query) => {
    try {
      const response = await fetch(
        `https://api.radar.io/v1/search/autocomplete?query=${query}`,
        {
          headers: {
            Authorization:
              "prj_test_pk_992f95733cee52fc55740a0a5efa5cff1cb1fcaf",
          },
        }
      );
      const data = await response.json();
      return data?.addresses ?? [];
    } catch (error) {
      console.error("Error searching zip code:", error);
      return [];
    }
  };