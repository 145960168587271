import moment from "moment";
import { animateScroll as scroll } from "react-scroll";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const formatPhoneNumber = (input) => {
  if (input == null) return;
  // Remove non-numeric characters from the input
  const cleaned = input.replace(/\D/g, "");
  // Format the cleaned input as (000) 000-0000
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return input;
};

export const formatDate = (input) => {
  return input ? moment(input).format("MM/DD/YYYY") : "---";
};

export const formatTime = (input) => {
  return input ? moment(input).format("hh:mm A") : "---";
};

export const formatProfessionsList = (data) => {
  let updatedProfessionList = [];
  if (data && data.length > 0) {
    for (const item of data) {
      updatedProfessionList.push(item);
      if (item?.divisions.length > 0) {
        updatedProfessionList = [...updatedProfessionList, ...item.divisions];
      }
    }
  }
  return updatedProfessionList;
};

export const generateFullImageUrl = (photoUrl) => {
  const baseeUrl = `${baseUrl}storage/`;
  return baseeUrl + photoUrl;
};

export const formatAddress = (city, state, postalCode) => {
  if (city || state || postalCode) {
    return `${city ?? ""} ${state ?? ""}, ${postalCode ?? ""}`;
  } else {
    return "1649 Forum Place, Suite 2, West Palm Beach, California, 33401";
  }
};

export const getPathForProfile = (userInfo, step) => {
  let path = "/dashboard";
  const {
    photo,
    about_me,
    services,
    insuranceCarriers,
    officePhotos,
    credits,
    profile_percentage,
    new_lead_sms_notification,
  } = userInfo ?? {};
  const isTemplate = JSON.parse(localStorage.getItem("smsTemplate")) ?? false;
  const isInsurance =
    JSON.parse(localStorage.getItem("isUpdateInsurancePer")) ?? false;
  if (profile_percentage == "100" && credits !== null) return path;
  if (
    (photo == null || photo === "" || about_me == null || about_me === "") &&
    (!step || step < 1)
  ) {
    path = "/profile-optimization/1";
  }
  // else if (services && services.length < 1 && (!step || step < 2)) {
  //   path = "/profile-optimization/2";
  // }
  else if (
    insuranceCarriers &&
    insuranceCarriers.length < 1 &&
    !isInsurance &&
    (!step || step < 3)
  ) {
    path = "/profile-optimization/2";
  } else if (
    new_lead_sms_notification &&
    new_lead_sms_notification == 1 &&
    !isTemplate &&
    (!step || step < 4)
  ) {
    path = "/profile-optimization/3";
  } else if (officePhotos && officePhotos.length < 1 && (!step || step < 5)) {
    path = "/profile-optimization/4";
  } else if (credits === null) {
    path = "/profile-optimization/package-plan";
  }
  return path;
};

export const profilePercentages = {
  about_me: 10,
  photo: 10,
  services: 20,
  insuranceCarriers: 20,
  new_lead_sms_notification: 20,
  officePhotos: 20,
};

const getProfilePercentage = (UpdatedProfessionalInfo)=>{
  let percentage = 0
  const {services,officePhotos,insuranceCarriers,about_me,photo} = UpdatedProfessionalInfo
  if(Array.isArray(services) && services.length > 0){
    percentage = percentage+20
  }
  if(Array.isArray(officePhotos) && officePhotos.length > 0){
    percentage = percentage+20
  }
  if(about_me && about_me !== null){
    percentage = percentage+10
  }
  if(photo && photo !== null){
    percentage = percentage+10
  }
  const isUpdate = localStorage.getItem("isUpdateInsurancePer");
      if (!JSON.parse(isUpdate)){

        percentage = percentage+20
      }
  if(Array.isArray(insuranceCarriers) && insuranceCarriers.length > 0){
  }
  
  
}


export const getUpdateProfilePercentage = (professionalInfo, propertyName) => {
  let percentage;
  if (professionalInfo.data.profile_percentage === null) {
    percentage = 0;
  } else {
    percentage = parseInt(professionalInfo.data.profile_percentage);
  }
  const propertyValue = professionalInfo.data[propertyName];
  switch (propertyName) {
    case "services":
    case "officePhotos":
      percentage =
        Array.isArray(propertyValue) && propertyValue.length > 0
          ? percentage
          : profilePercentages[propertyName] + percentage;
      break;
    case "insuranceCarriers":
      const isUpdate = localStorage.getItem("isUpdateInsurancePer");
      if (!JSON.parse(isUpdate)) {
        localStorage.setItem("isUpdateInsurancePer", true);
        percentage = profilePercentages[propertyName] + percentage;
      }
      break;
    case "about_me":
    case "photo":
      percentage =
        propertyValue && propertyValue !== null
          ? percentage
          : profilePercentages[propertyName] + percentage;
      break;
    case "new_lead_sms_notification":
      const smsTemp = localStorage.getItem("smsTemplate");
      percentage =
        smsTemp && propertyValue && propertyValue !== null
          ? percentage
          : profilePercentages[propertyName] + percentage;
      break;
  }

  if (percentage > 100) {
    return parseInt(professionalInfo.data.profile_percentage);
  } else {
    return percentage;
  }
};

export const formatQuillResponse = (input) => {
  // Create a new div element
  var doc = new DOMParser().parseFromString(input, "text/html");

  // Extract and return the text content
  return doc.body.textContent || "";
};

export const scrollToTop = () => {
  const options = {
    duration: 0,
    smooth: true,
  };
  scroll.scrollToTop(options);
};

export const getNumberFromString = (input) => {
  let matchedInput = input.match(/\d+/);
  return matchedInput ? matchedInput[0] : input;
};

export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const insertLineBreaks = (names, len) => {
  // Split the input string into an array of words
  const words = names?.split(" ");

  // Initialize variables
  let result = "";
  let lineLength = 0;

  // Iterate through each word
  for (let i = 0; i < words?.length; i++) {
    const word = words[i];
    const wordLength = word.length;

    // Check if adding the current word would exceed the character limit
    if (lineLength + wordLength > len) {
      // Add a line break before adding the current word
      result += "<br />";
      lineLength = 0;
    }

    // Add the current word to the result
    result += word;

    // Update the line length
    lineLength += wordLength;

    // Add a space if it's not the last word
    if (i < words.length - 1) {
      result += " ";
      lineLength++; // Increment line length to account for the space
    }
  }
  // const formattedNames = insertLineBreaks(names);
  // document.getElementById("output").innerHTML = result;

  return result;
};

export const ehrTags = [
  "+Match First Name",
  "+Match Last Name",
  "+Match FULL Name",
  "+OFFICE PHONE",
  "+appt schedule link",
  "+MY profession",
  "+MY name",
  "+EHR SMS",
];

export const convertShortCodes = (content, data) => {
  const {
    first_name,
    last_name,
    office_phone_number,
    professions,
    schedule_url,
    ehr_sms,
  } = data ?? {};
  const template = content;

  const hasDrPrefix = professions?.some(
    (profession) => profession.add_dr_prefix === 1
  );

  const fullName = `${hasDrPrefix && "Dr."} ${first_name ?? ""} ${
    last_name ?? ""
  }`;
  const matchFullName = `${first_name ?? ""} ${last_name ?? ""}`;

  const message = template
    .replaceAll("{{match_first_name}}", first_name ?? "")
    .replaceAll("{{match_last_name}}", last_name ?? "")
    .replaceAll("{{my_name}}", fullName)
    .replaceAll("{{my_profession}}", professions[0]?.name ?? "")
    .replaceAll("{{office_phone}}", office_phone_number ?? "")
    .replaceAll("{{schedule_link}}", schedule_url ?? "")
    .replaceAll("{{ehr_sms}}", ehr_sms ?? "")
    .replaceAll("{{match_full_name}}", matchFullName ?? "");

  return message;
};

export const convertMentalHealthCounselorKeyword = (keyword) => {
  const lowerCasedKeyword = keyword?.toLowerCase();
  switch (lowerCasedKeyword) {
    case "anger management counseling":
      return "Anger Management Counselor";
    case "marriage counseling":
      return "Marriage Counselor";
    case "internal family systems":
      return "IFS Therapist";
    case "emdr ":
      return "EMDR Therapist";
    case "ptsd counseling ":
      return "PTSD Counselor";
    case "couples counseling ":
      return "Couples Counselor";
    case "christian counseling ":
      return "Christian Counselor";
    case "pre marriage counseling":
    case "pre-marriage counseling":
      return "Pre-Marriage Counselor";
    case "family counseling":
      return "Family Counselor";
    case "relationship counseling":
      return "Relationship Counselor";
    case "psychotherapy":
      return "Psychotherapist";
    case "grief counseling":
      return "Grief Counselor";
    case "anxiety counseling":
      return "Anxiety Counselor";
    case "depression counseling":
      return "Depression Counselor";
    case "trauma counseling":
      return "Trauma Counselor";
    case "divorce counseling":
      return "Divorce Counselor";
    case "infidelity counseling":
      return "Infidelity Counselor";
    case "parenting counseling":
      return "Parenting Counselor";
    case "mens counseling":
    case "men's counseling":
      return "Men's Counselor";
    case "womens counseling":
    case "women's counseling":
      return "Women's Counselor";
    case "pyschotherapy":
      return "Pyschotherapist";
    case "lgbtq counseling":
      return "LGBTQ Counselor";
    case "psychologist":
      return "psychologist";
    default:
      return keyword;
  }
};

export const updatedProfessionName = (keyword) => {
  const lowerCasedKeyword = keyword?.toLowerCase();
  switch (lowerCasedKeyword) {
    case "anger management counseling":
      return "Anger Management Counselor";
    case "marriage counseling":
      return "Marriage Counselor ";
    case "internal family systems":
    case "internal family systems (ifs)":
      return "IFS Therapist";
    case "emdr therapy":
    case "emdr":
      return "EMDR Therapist";
    case "ptsd counseling":
      return "PTSD Counselor";
    case "couples counseling":
      return "Couples Counselor";
    case "christian counseling":
      return "Christian Counselor";
    case "pre marriage counseling":
    case "pre-marriage counseling":
      return "Pre-Marriage Counselor";
    case "family counseling":
      return "Family Counselor";
    case "relationship counseling":
      return "Relationship Counselor";
    case "psychotherapy doctor":
    case "psychotherapy":
      return "Psychotherapist";
    case "grief counseling":
      return "Grief Counselor";
    case "anxiety counseling":
      return "Anxiety Counselor";
    case "depression counseling":
      return "Depression Counselor";
    case "trauma counseling":
      return "Trauma Counselor";
    case "divorce counseling":
      return "Divorce Counselor";
    case "infidelity counseling":
      return "Infidelity Counselor";
    case "parenting counseling":
      return "Parenting Counselor";
    case "mens counseling":
    case "men's counseling":
      return "Mens Counselor";
    case "womens counseling":
    case "women's counseling":
      return "Women's Counselor";
    case "pyschotherapy":
      return "Pyschotherapist";
    case "lgbtq counseling":
      return "LGBTQ Counselor";
    default:
      return keyword;
  }
};

export const getIsMHCName = (profession) => {
  if (profession?.vanity_name && profession?.vanity_name !== null) {
    return profession.vanity_name;
  } else {
    return profession?.name ?? "Mental Health Counselor";
  }
};


export const adjustLeadLimits = (dailyLimit, weeklyLimit)=> {
  const maxWeeklyLimit = dailyLimit * 7;
  if (maxWeeklyLimit > weeklyLimit) {
    return true;
  } else {
    return false;
  }
}



export const removeDoctorIfNearMe = (query , trim) => {
  if (query?.toLowerCase().includes("near me")) {
    if(trim)
    {
      return query.replace(/\bnear me\b/gi, "").replace(/\s{2,}/g, " ").trim();
    }
    else {
      return query
      .replace(/\bdoctor\b/gi, '') 
      .replace(/\bnear me\b/gi, "near you")
      .replace(/\s{2,}/g, ' ') 
      .trim();
    }
 
  }
    return query
}

export const formatMessageDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return { label: "", date: null };

  const momentDate = moment(date);
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  let label;
  if (momentDate.isSame(today, "d")) {
    label = `Today ${momentDate.format(format)}`;
  } else if (momentDate.isSame(yesterday, "d")) {
    label = `Yesterday ${momentDate.format(format)}`
  } else {
    label = momentDate.format("MM/DD/YYYY");
  }
  return label
};


export const formatDollar = (amount) =>{
  if (isNaN(amount)) return "Invalid number";
  
  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(amount);
}

export function getGclidFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const gclid = urlParams.get('gclid');
  if (gclid) {
    sessionStorage.setItem('gclid', gclid);
  }
}
