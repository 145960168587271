import React, { useState , useEffect } from "react";
import '../../Loader.css'

const Loader = ({isLoading,description=""}) => {
 const [isShowLoading , setIsShowLoading]=useState(isLoading)
  useEffect(() => {
    setTimeout(() => {
      setIsShowLoading(false)
      description&&console.log(description);
    }, 7000);
  }, [isLoading])
  

  return (
    <>
      {isShowLoading && (
       <div className="loader-wrapper w-full h-full flex items-center justify-center">
         <div className="loader"></div>
       </div>
      )}
    </>
  );
};

export default Loader;
