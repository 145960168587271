import { DentistMetaInfo } from "./dentist";
import {  ChiropractorMetaInfo} from "./chiropractor";
import {  MHCMetaInfo } from "./mhc.js";
import { MHCVirtualMetaInfo } from "./mhc-virtual";
import { psychiatricMetaInfo } from "./psychiatric-provider";

export const metaInfo = (profession, keyword) => {
  switch (profession) {
    case "dentist":
      return DentistMetaInfo[keyword];
    case "chiropractor":
      return ChiropractorMetaInfo[keyword];
    case "mental-health-counselor":
      return MHCMetaInfo[keyword];
    case "virtual-mental-health-counselor":
        return MHCVirtualMetaInfo[keyword];
    case "psychiatric-provider":
        return psychiatricMetaInfo[keyword]
    default:
      break    
  }
};
