
  export const profiles = [
    {
      id: "1",
      name: "Dr. Michael Chen",
      title: "Dentist",
      company: "Bright Smile Dental Care",
      location: "San Francisco, CA",
      bio: "Specialized in cosmetic dentistry with 12+ years of experience. Passionate about providing painless dental care and patient education on oral health.",
      interests: ["Cosmetic Dentistry", "Preventative Care", "Dental Technology"],
      imageUrl: "https://images.unsplash.com/photo-1622253692010-333f2da6031d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      connectionStrength: 85,
      mutualConnections: 8,
      tags: ["Dentist", "Cosmetic", "Family Care"],
    },
    {
      id: "2",
      name: "Dr. Sarah Johnson",
      title: "Mental Health Counselor",
      company: "Mindful Wellness Center",
      location: "Boston, MA",
      bio: "Licensed therapist specializing in anxiety, depression, and trauma. Integrates CBT, mindfulness, and holistic approaches to mental wellness.",
      interests: ["Cognitive Behavioral Therapy", "Trauma Recovery", "Mindfulness"],
      imageUrl: "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      connectionStrength: 92,
      mutualConnections: 5,
      tags: ["Therapist", "CBT", "Anxiety"],
    },
    {
      id: "3",
      name: "Dr. James Wilson",
      title: "Chiropractor",
      company: "Align Spine Chiropractic",
      location: "Portland, OR",
      bio: "Focusing on sports injuries and chronic pain management. Combines traditional adjustments with modern rehabilitation techniques for holistic healing.",
      interests: ["Sports Rehabilitation", "Spinal Adjustment", "Pain Management"],
      imageUrl: "https://images.unsplash.com/photo-1582750433449-648ed127bb54?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      connectionStrength: 78,
      mutualConnections: 10,
      tags: ["Chiropractor", "Sports", "Pain Relief"],
    },
    {
      id: "4",
      name: "Dr. Emily Rodriguez",
      title: "Orthodontist",
      company: "Perfect Smile Orthodontics",
      location: "Austin, TX",
      bio: "Specialist in modern orthodontic solutions including Invisalign and ceramic braces. Dedicated to creating beautiful smiles for patients of all ages.",
      interests: ["Invisalign", "Pediatric Orthodontics", "Digital Dentistry"],
      imageUrl: "https://images.unsplash.com/photo-1614608682850-e0d6ed316d47?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      connectionStrength: 89,
      mutualConnections: 6,
      tags: ["Orthodontist", "Invisalign", "Braces"],
    },
    {
      id: "5",
      name: "Dr. Robert Kim",
      title: "Physical Therapist",
      company: "Motion Recovery Center",
      location: "Chicago, IL",
      bio: "Specialized in neurological rehabilitation and geriatric care. Committed to helping patients regain mobility and improve quality of life through personalized therapy.",
      interests: ["Neurological Rehabilitation", "Geriatric Care", "Manual Therapy"],
      imageUrl: "https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      connectionStrength: 76,
      mutualConnections: 9,
      tags: ["PT", "Rehabilitation", "Senior Care"],
    },
    {
      id: "6",
      name: "Dr. Amanda Patel",
      title: "Mental Health Psychologist",
      company: "Thrive Psychology Group",
      location: "Seattle, WA",
      bio: "Clinical psychologist specializing in relationship counseling and workplace stress. Uses evidence-based approaches to help clients achieve emotional balance.",
      interests: ["Relationship Therapy", "Workplace Stress", "Emotional Regulation"],
      imageUrl: "https://images.unsplash.com/photo-1551836022-aadb801c60e9?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      connectionStrength: 94,
      mutualConnections: 7,
      tags: ["Psychologist", "Relationships", "Stress"],
    },
  ];