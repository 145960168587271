import { useEffect } from "react";

const useMeta = (title, description) => {
  useEffect(() => {
    // Update document title
    if (typeof title === "string" && title.trim() !== "") {
      document.title = title;
    }

    // Update meta description
    if (typeof description === "string" && description.trim() !== "") {
      let metaDescription = document.querySelector("meta[name='description']");

      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }

      metaDescription.content = description;
    }
  }, [title, description]); // Runs whenever title or description changes
};

export default useMeta;