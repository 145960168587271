import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { generateFullImageUrl } from "../../../../utilities/utils";
import CustomNumberField from "../../../customNumberField";
import { useLicenseAndStateUpdateMutation } from "../../../../store/admin/professionalUserInfo";
import CustomInputField from "../../../customInputField";
import { useGetStatesQuery } from "../../../../store/professions";
import { MapPinIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import IconButton from "../../../iconButton";
import { toast } from "react-toastify";

const ProfessionalVirtualLeadDetail = ({
  professionalId,
  profession,
  states,
  otherStates,
  selectedClientLeadDetails,
  setActiveButtonValue,
  handleResetClient,
  isProfessionalSuccess,
  isDisabled,
}) => {
  const isMobile =
    window.matchMedia &&
    window.matchMedia("only screen and (max-width: 767px)").matches;

  const { data: usStates } = useGetStatesQuery();

  const [
    saveProfessionalInfo,
    {
      isSuccess: saveClientLeadSuccess,
      isLoading: saveClientLeadLoader,
      isError: saveClientLeadError,
      data: updatedClientLeadInfo,
    },
  ] = useLicenseAndStateUpdateMutation();

  const [licenseNumberWithState, setLicenseNumberWithState] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  const [activeButton, setActiveButton] = useState(
    states.length || profession.allowVirtualLead ? "yes" : "no"
  );
  const [showClientLeadOptions, setShowClientLeadOptions] = useState(
    activeButton === "yes"
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStates, setFilteredStates] = useState(usStates);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedClientLeadName, setSelectedClientLeadName] = useState();

  useEffect(() => {
    if (usStates) {
      setFilteredStates(usStates);
      setShowClientLeadOptions(activeButton === "yes");
    }
  }, [activeButton, usStates]);

  useEffect(() => {
    if (selectedClientLeadDetails && selectedClientLeadDetails?.length > 0) {
      setSelectedOptions(selectedClientLeadDetails?.map((item) => item.id));
      setSelectedClientLeadName(selectedClientLeadDetails?.map((item) => item));
      setLicenseNumberWithState(
        selectedClientLeadDetails?.map((item) => ({
          state: item.name,
          profession: item.profession,
          license: item.license,
          stateId: item.id,
        }))
      );
      setShowClientLeadOptions(true);
      setActiveButton("yes");
    } else {
      setSelectedOptions([]);
      setSelectedClientLeadName([]);
      setShowClientLeadOptions(false);
      if(!profession.allowVirtualLead) setActiveButton("no");
    }
  }, [isProfessionalSuccess]);

  useEffect(() => {
    if (!saveClientLeadLoader) {
      if (saveClientLeadSuccess) {
        toast.success(updatedClientLeadInfo.message);
      } else if (saveClientLeadError && saveClientLeadError) {
        toast.error("Something went wrong, please try again");
      }
    }
    setHasChange(false)
  }, [saveClientLeadSuccess, saveClientLeadError]);

  const removeStateById = (stateIdToRemove) => {
    setLicenseNumberWithState((prevStates) =>
      prevStates.filter((item) => item.stateId !== stateIdToRemove)
    );
  };

  const handleLicenseNumberChange = (value, stateName, profession, stateId) => {
    setLicenseNumberWithState((prev) => {
      const updatedList = prev?.map((item) =>
        item.state === stateName ? { ...item, license: value } : item
      );
  
      const newStateList = prev?.some((item) => item.state === stateName)
        ? updatedList
        : [...prev, { state: stateName, profession, license: value, stateId }];
  
      setHasChange(hasStateChanged(states, newStateList));
  
      return newStateList;
    });
  };  

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = usStates.filter((states) =>
      states?.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredStates(filtered);
  };

  const handleButtonClick = (value) => {
    setActiveButton(value);
    setActiveButtonValue && setActiveButtonValue(value);
    if (value === "yes") {
      setShowClientLeadOptions(true);
      setSelectedOptions(selectedClientLeadDetails?.map((item) => item.id));
      setSelectedClientLeadName(selectedClientLeadDetails?.map((item) => item));
      handleResetClient && handleResetClient("yes");
    } else {
      setSelectedOptions([]);
      setSelectedClientLeadName([])
      setHasChange(hasStateChanged(states,[]))
      handleResetClient && handleResetClient("no");
      setShowClientLeadOptions(false);
    }
  };

  const handleCheckboxChange = (stateId,stateName) => {
    const newSelectedOptions = [...selectedOptions];
    if (newSelectedOptions.includes(stateId)) {
      removeStateById(stateId);
      const index = newSelectedOptions.indexOf(stateId);
      newSelectedOptions.splice(index, 1);
    } else {
      const foundClientLead = selectedClientLeadDetails?.find(
        (clientLead) => clientLead.id === stateId
      );
      if (foundClientLead) {
        handleLicenseNumberChange(
          foundClientLead.license,
          foundClientLead.name,
          foundClientLead.profession,
          foundClientLead.id
        );
      } else 
      {
        handleLicenseNumberChange(null, stateName, profession.id, stateId)
      }
      newSelectedOptions.push(stateId);
    }
    setSelectedOptions(newSelectedOptions);
    let filteredSelectedOptions = usStates.filter((state) =>
    newSelectedOptions.includes(state.id)
    );
    setHasChange(hasStateChanged(states,filteredSelectedOptions))
    setSelectedClientLeadName(filteredSelectedOptions);
    setSearchQuery("");
    setFilteredStates(usStates);
  };

  const getIsOptionSelected = (id) => {
    const response = selectedOptions && selectedOptions.includes(id);
    return response;
  };

  const handleRemoveItem = (idToRemove) => {
    removeStateById(idToRemove);
    const updatedSelectedClientLeadName = selectedClientLeadName.filter(
      (insurance) => insurance.id !== idToRemove
    );
    const indexToRemove = selectedOptions.indexOf(idToRemove);
    if (indexToRemove !== -1) {
      selectedOptions.splice(indexToRemove, 1);
    }
    setSelectedClientLeadName(updatedSelectedClientLeadName);
    setSelectedOptions([...selectedOptions]);
  };

  const handleSaveStateAndLIncense = () => {
    let params;
    if (activeButton == "yes") {
      params = {
        states: [
          ...(licenseNumberWithState && licenseNumberWithState.length > 0
            ? licenseNumberWithState.map((item) => ({
                state_id: item.stateId,
                profession_id: item.profession,
                license_number: item.license,
              }))
            : []),
          ...otherStates,
        ],
      };
    } else {
      params = {
        states: otherStates,
      };
    }
    saveProfessionalInfo({
      id: professionalId,
      params,
    });
  };

  function hasStateChanged(existingStates, selectedStates) {
    if (existingStates?.length !== selectedStates?.length) {
        return true;
    }
    const existingMap = new Map(existingStates?.map(state => [state.id, state.license_number??'']));
    for (const state of selectedStates) {
        if (!existingMap.has(state.stateId) || (existingMap.get(state.stateId) !== state.license)) {
            return true; 
        }
    }
    return false; 
}

  const buttonStyleYes = {
    backgroundColor: activeButton === "yes" ? "#1E90FE" : "",
    color: activeButton === "yes" ? "white" : "black",
  };

  const buttonStyleNo = {
    backgroundColor: activeButton === "no" ? "#1E90FE" : "",
    color: activeButton === "no" ? "white" : "black",
  };
  return (
    <div
      className={`${
        isDisabled ? "grayscale opacity-80 pointer-events-none" : ""
      }`}
    >
      <div className="grid md:items-center grid-cols-1 md:grid-cols-2 gap-5 xl:gap-[70px] pt-6 pb-5">
        <h6 className="text-xs font-bold leading-[160%] uppercase text-customGray">
          would you like virtual client leads?
        </h6>
        <div className="flex items-center gap-[14px]">
          <button
            className="text-base font-medium leading-[160%] text-white border border-solid border-[#EBEFF3] rounded-[10px] py-[6px] px-[18px]"
            style={buttonStyleYes}
            onClick={() => handleButtonClick("yes")}
          >
            Yes
          </button>
          <button
            className="text-base font-medium leading-[160%] text-customGray border border-solid border-[#EBEFF3] rounded-[10px] py-[6px] px-[18px]"
            style={buttonStyleNo}
            onClick={() => handleButtonClick("no")}
          >
            No
          </button>
        </div>
      </div>
      {showClientLeadOptions && (
        <div className="border-t pt-5 grid grid-cols-1 xl:grid-cols-2 gap-5 xl:gap-x-[70px]">
          <div className="">
            <h6 className="text-xs font-bold leading-[160%] uppercase text-customGray mb-[6px]">
              States where you accept virtual Clients:
            </h6>
            <p className="text-sm leading-[160%] text-[#485868] mb-[22px]">
              You can select as many states as you'd like, and we'll do our best
              to deliver leads from each of your selected virtual regions.
            </p>
            <ul className="flex items-center gap-4 flex-wrap">
              {selectedClientLeadName &&
                selectedClientLeadName?.map((insurance) => (
                  <li className="flex items-center gap-2.5 h-10 text-xs font-medium leading-[160%] text-customGray p-2.5 rounded-[10px] bg-[#F1F4F6]">
                    {insurance.name}{" "}
                    <XMarkIcon
                      className="w-4 h-4 text-[#768B9F] cursor-pointer"
                      onClick={() => handleRemoveItem(insurance.id)}
                    />
                  </li>
                ))}
            </ul>
          </div>
          <div className="mr-2">
            <div className="flex items-center mx-auto w-full border border-solid border-[#CCD0D4] rounded-[10px] h-[48px] bg-white mb-3">
              <span className="pl-[14px] md:pl-[16px] pr-[10px]">
                <MagnifyingGlassIcon className="w-6 h-6 text-[#485868]" />
              </span>
              <input
                type="text"
                name="search-states"
                onChange={handleSearchChange}
                value={searchQuery}
                placeholder="Search for States..."
                className="border-r-0 text-sm font-normal text-customGray placeholder:text-[#869099] bg-transparent"
              />
            </div>
            {isMobile && searchQuery && (
              <ul className="scroller max-h-[500px] overflow-y-auto pr-1">
                {filteredStates &&
                  filteredStates?.map((state) => (
                    <li
                      key={state.id}
                      className={`form-group flex items-center border border-solid cursor-pointer border-[#CCD0D4] rounded-[10px] min-h-[48px] py-[11px] px-4 mb-3 ${
                        getIsOptionSelected(state.id)
                          ? "border-primary border-[1.5px]"
                          : "border-[#CCD0D4]"
                      }`}
                      onClick={() => handleCheckboxChange(state.id,state.name)}
                    >
                      <div className="flex items-center relative">
                        <input
                          id={`option-${state.id}`}
                          aria-describedby="comments-description"
                          name="stateOptions"
                          type="checkbox"
                          checked={
                            selectedOptions &&
                            selectedOptions.includes(state.id)
                          }
                          onChange={() => handleCheckboxChange(state.id,state.name)}
                          className="rounded border-[1.5px] border-[#CCD0D4] relative cursor-pointer inline-block w-5 h-5 leading-[0px]"
                        />
                      </div>
                      <div className="min-w-0 flex-1 text-sm leading-6 pl-[14px]">
                        <label className="font-medium text-customGray text-sm md:text-base leading-[160%] cursor-pointer">
                          {state.name}
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
            {!isMobile && (
              <ul className="scroller max-h-[500px] overflow-y-auto pr-1">
                {filteredStates &&
                  filteredStates?.map((state) => (
                    <li
                      key={state.id}
                      className={`form-group flex items-center border border-solid cursor-pointer border-[#CCD0D4] rounded-[10px] min-h-[48px] py-[11px] px-4 mb-3 ${
                        getIsOptionSelected(state.id)
                          ? "border-primary border-[1.5px]"
                          : "border-[#CCD0D4]"
                      }`}
                      onClick={() => handleCheckboxChange(state.id,state.name)}
                    >
                      <div className="flex items-center relative">
                        <input
                          id={`option-${state.id}`}
                          aria-describedby="comments-description"
                          name="stateOptions"
                          type="checkbox"
                          checked={
                            selectedOptions &&
                            selectedOptions.includes(state.id)
                          }
                          onChange={() => handleCheckboxChange(state.id,state.name)}
                          className="rounded border-[1.5px] border-[#CCD0D4] relative cursor-pointer inline-block w-5 h-5 leading-[0px]"
                        />
                      </div>
                      <div className="min-w-0 flex-1 text-sm leading-6 pl-[14px]">
                        <label className="font-medium text-customGray text-sm md:text-base leading-[160%] cursor-pointer">
                          {state.name}
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
          <div></div>
        </div>
      )}
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-3 xl:gap-[40px pb-6">
        {activeButton == "yes" &&
          selectedClientLeadName?.map((state, index) => {
            const licenseValue =
              licenseNumberWithState.find((item) => item.state === state.name)
                ?.license || "";

            return (
              <div key={index}>
                <h6 className="text-xs font-bold leading-[160%] text-[#869099] uppercase mb-2">
                  {`License number in ${state.name}`}
                </h6>
                <CustomInputField
                  icon={null}
                  placeHolder="0000000"
                  type="text"
                  value={licenseValue}
                  name="license-number"
                  onChange={(e) =>
                    handleLicenseNumberChange(
                      e.target.value,
                      state.name,
                      profession.id,
                      state.id
                    )
                  }
                  classes="bg-white lg:h-[60px] flex items-center max-w-[626px] mx-auto w-full border border-solid border-strokeColor rounded-[10px] h-[48px] overflow-hidden"
                  inputClasses="border-r-0 lg:text-lg text-sm font-medium text-customGray placeholder:text-[#869099] pl-[10px] pr-[20px] bg-transparent"
                />
              </div>
            );
          })}
      </div>
      {hasChange&&!isDisabled && (
        <div className="flex justify-end w-full">
          <IconButton
            title="Save"
            icon={CheckCircleIcon}
            iconClasses="w-6 h-6 text-white"
            classes={`text-sm font-black leading-[160%] text-white max-w-[160px] md:max-w-[128px] w-full flex items-center justify-center gap-[6px] bg-primary h-10 rounded-[10px] cursor-pointer opacity-1 ${
              saveClientLeadLoader && "pointer-events-none"
            }`}
            onClick={() => handleSaveStateAndLIncense()}
            buttonLoader={saveClientLeadLoader}
          />
        </div>
      )}
    </div>
  );
};

export default ProfessionalVirtualLeadDetail;
