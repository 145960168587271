import React from "react";
import LandingPageNavbar from "../components/landingPageNavbar";
import VirtualLeadMatchHero from "../components/virtualLeadMatch/VirtualLeadMatchHero";
// import "../components/ProfileCarousel/ProfileCarousel.css"
import LeadFooter from '../components/footer/LeadFooter';

const VirtualLeadMatchPage = () => {
  return (
    <main>
      <LandingPageNavbar />
      <main>
        <VirtualLeadMatchHero />
      </main>
      <LeadFooter />
    </main>
  );
};

export default VirtualLeadMatchPage;
