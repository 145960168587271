export const DentistMetaInfo = {
    "Dentist":{
     title:"Dentist Near Me",
     description:"Find trusted, verified dentists near you on HelpMatch. Book appointments effortlessly with qualified professionals today."
    },
    "dentist near me":{
        title:"Dentist Near Me",
        description:"Find trusted, verified dentists near you on HelpMatch. Book appointments effortlessly with qualified professionals today."
       },
    "cosmetic dentist":{
        title:"Cosmetic Dentist Near Me",
        description:"Top-rated cosmetic dentists on HelpMatch. Transform your smile with expert care and convenient appointment booking."
    },
    "crowns dentist":{
       title:"Crown Dentist Near Me",
       description:"Find expert crowns dentists on HelpMatch. Restore your smile with trusted professionals and seamless appointment scheduling."
    },
    "dental hygienist":{
         title :"Dental Hygienist Near Me",
         description:"Connect with skilled dental hygienists on HelpMatch. Ensure optimal oral health with trusted care and easy bookings."
    },
    "dental implants dentist" :{
       title:"Dental Implants Dentist Near Me",
       description:"Trusted dental implant specialists on HelpMatch. Achieve a confident smile with expert care and simple scheduling."
    },
    "Dental X-rays":{
        title:"Dental X-Rays Near Me",
        description:"Professional dental X-ray services on HelpMatch. Ensure accurate diagnostics with qualified providers and convenient scheduling."
    },
    "dentist that accepts your insurance": {
        title:"Dentist Accepting Insurance Near",
        description:"Discover dentists accepting your insurance on HelpMatch. Enjoy affordable, quality care with easy appointment booking today."
    },
    "dentures dentist":{
        title:"Dentures Dentist Near Me",
        description:"Find experienced dentures dentists on HelpMatch. Restore your smile confidently with trusted care and convenient scheduling."
    },
    "emergency dentist":{
       title:"Emergency Dentist Near Me",
       description:"Connect with emergency dentists on HelpMatch. Receive prompt, reliable dental care when you need it most."
    },
    "endodontist":{
       title:"Endodonstist Near Me",
       description:"Find expert endodontists on HelpMatch. Ensure precision care for root canals and more with easy appointment scheduling."
    },
    "family dentist":{
        title:"Family Dentist Near Me",
        description:"Discover trusted family dentists on HelpMatch. Enjoy comprehensive dental care for all ages with simple scheduling."
    },
    "general dentist":{
        title:"General Dentist Near Me",
        description:"Find reliable general dentists on HelpMatch. Access comprehensive dental care and schedule appointments with ease."
    },
    "invisalign dentist":{
        title:"Invisalign Dentist Near Me",
        description:"Discover certified Invisalign dentists on HelpMatch. Achieve a straighter smile with expert care and easy scheduling."
    },
    "orthodontist":{
       title:"Orthodontist Near Me",
       description:"Find experienced orthodontists on HelpMatch. Transform your smile with expert orthodontic care and convenient appointment booking."
    },
    "periodontist":{
      title:"Periodontist Near Me",
      description:"Connect with skilled periodontists on HelpMatch. Ensure expert gum care and oral health with easy appointment scheduling."
    },
    "prosthodontist":{
        title:"Prosthodontist Near Me",
        description:"Find trusted prosthodontist on HelpMatch. Restore your smile with expert care and convenient appointment scheduling."
    },
    "root canal dentist":{
      title:"Root Canal Dentist Near Me",
      description:"Expert root canal dentists on HelpMatch. Receive precise, trusted care with seamless appointment booking."
    },
    "sedation dentist":{
         title:"Sedation Dentist Near Me",
         description:"Find experienced sedation dentists on HelpMatch. Enjoy stress-free dental care with trusted professionals and easy scheduling."
    },
    "teeth whitening dentist":{
        title:"Teeth Whitening Dentist Near Me",
        description:"Professional teeth whitening dentists on HelpMatch. Brighten your smile with expert care and easy booking."
    },
    "tooth extraction dentist":{
        title:"Tooth Extraction Dentist Near Me",
        description:"Find skilled tooth extraction dentists on HelpMatch. Receive safe, reliable care with easy appointment scheduling."
    }
    
}




