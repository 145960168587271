export const MHCMetaInfo = {
    "mental health counselor":{
        title:"Mental Health Counselor Near Me",
        description:"Connect with a verified mental health counselor. Compassionate, professional support for emotional well-being and personal growth."
       },
    "Mental Health Counselor":{
        title:"Mental Health Counselor Near Me",
        description:"Connect with a verified mental health counselor. Compassionate, professional support for emotional well-being and personal growth."
       },
    "anxiety counseling":{
        title:"Anxiety Counseling Near Me",
        description:"Access verified anxiety counseling professionals. Compassionate support to manage stress, reduce anxiety, and improve mental wellness."
    },
    "christian counseling" :{
        title:"Christian Counseling Near Me",
        description:"Connect with verified Christian counseling professionals. Faith-based support for emotional healing, spiritual growth, and personal well-being."
    },
    "couples counseling":{
        title:"Couples Counseling Near Me",
        description:"Find top-rated couples counseling professionals near you. Strengthen your relationship with expert guidance for better communication and connection."
    },
    "depression counseling":{
        title:"Depression Counseling Near Me",
        description:"Access verified depression counseling professionals. Compassionate care to navigate challenges, improve mood, and enhance emotional well-being."
    },
    "divorce counseling":{
        title:"Divorce Counseling Near Me",
        description:"Find expert divorce counseling services. Supportive professionals helping you navigate emotional challenges and embrace new beginnings."
    },
    "emdr":{
        title:"EMDR Therapy Near Me",
        description:"Discover verified EMDR therapy professionals. Specialized care to process trauma, reduce distress, and promote emotional healing."
    },
    "family counseling":{
        title:"Family Counseling Near Me",
        description:"Access trusted family counseling professionals. Strengthen relationships, resolve conflicts, and foster harmony within your family unit."
    },
    "grief counseling":{
        title:"Grief Counseling Near Me",
        description:"Find compassionate grief counseling professionals. Expert support to navigate loss, heal emotionally, and rebuild your sense of peace."
    },
    "infidelity counseling":{
        title:"Infidelity Counseling Near Me",
        description:"Access verified infidelity counseling services. Expert guidance to rebuild trust, improve communication, and heal your relationship."
    },
    "internal family systems":{
        title:"IFS Therapist Near Me",
        description:"Explore Internal Family Systems therapy with verified professionals. Transformative care to foster self-awareness, healing, and emotional balance."
    },
   "lgbtq counseling":{
        title:"LGBTQ Counseling Near Me",
        description:"Find trusted LGBTQ+ counseling professionals. Inclusive support for mental health, identity exploration, and personal empowerment."
    },
    "marriage counseling":{
       title:"Marriage Counseling Near Me",
       description:"Discover verified marriage counseling professionals. Strengthen your partnership with expert guidance for improved communication and connection."
    },
    "mens counseling":{
        title:"Mens Counseling Near Me",
        description:"Access trusted men's counseling services. Professional support for personal growth, emotional well-being, and overcoming life challenges."
    },
    "parenting counseling":{
        title:"Parenting Counseling Near Me",
        description:"Find expert parenting counseling professionals. Gain valuable guidance to navigate challenges and strengthen family relationships effectively."
    },
    "pre marriage counseling":{
        title:"Pre-Marriage Counseling Near Me",
        description:"Top-rated pre-marriage counseling services near you. Expert guidance to build a strong foundation for a lasting partnership."
    },
    "psychotherapy":{
        title:"Psychotherapy Near Me",
        description:"Discover trusted psychotherapists near you. Comprehensive mental health support to promote healing, self-awareness, and emotional well-being."
    },
    "ptsd counseling":{
        title:"PTSD Counseling Near Me",
        description:"Find expert PTSD counseling services near you. Compassionate support to process trauma, reduce symptoms, and restore emotional balance."
    },
    "relationship counseling":{
        title:"Relationship Counseling Near Me",
        description:"Access trusted relationship counseling professionals. Expert guidance to improve communication, resolve conflicts, and strengthen connections."
    },
    "trauma counseling":{
        title:"Trauma Counseling Near Me",
        description:"Find experienced trauma therapists services. Compassionate care to process experiences, foster healing, and regain emotional resilience."
    },
    "womens counseling":{
        title:"Womens Counseling Near Me",
        description:"Access trusted women's counseling professionals near you. Supportive care for personal growth, emotional well-being, and life transitions."
    },
   "anger management counseling" : {
       title:"Anger Management Counseling Near Me",
       description:"Find expert anger management counseling with a verified mental health counselor. Effective strategies to foster emotional control."
    },
    "psychologist" : {
        title:"Psychologist Near Me",
        description:"Find a trusted psychologist near you. Comprehensive mental health support to promote healing, self-awareness, and emotional well-being."
     }
}