import React from "react";
import { useNavigate } from "react-router-dom";

const LeadFooter = () => {
  const navigate = useNavigate();
  const handlePrivacy = () => {
    navigate("/privacy-policy");
  };

  const handleUseTerms = () => {
    navigate("/terms-of-use");
  };

  return (
    <div>
      <div className="container">
        <div className="py-7 max-w-[820px] mx-auto text-left md:text-center">
          <p className="text-xs text-[#485868] mb-4">
            The information available on the HelpMatch website and mobile app is
            for general informational purposes only. It is not intended to serve
            as medical advice, diagnosis, or treatment. Always consult your
            healthcare provider directly for any medical concerns or specific
            health-related questions you may have.
          </p>
          <div className="md:gap-4 flex justify-center items-start md:items-center flex-col md:flex-row">
            <span className="text-sm text-black md:text-[#485868]">
              © 2024 HelpMatch.com
            </span>
            <span className="text-sm text-[#485868]">
              <span onClick={handleUseTerms} className="cursor-pointer">Terms of Use</span> |{" "}
              <span onClick={handlePrivacy} className="cursor-pointer">Privacy Policy</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadFooter;
