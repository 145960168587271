import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const adminTemplateCreation = createApi({
  reducerPath: "adminTemplateCreation",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + 'api/admin/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Accept", "application/json");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    adminSaveProfessionalTemplates: builder.mutation({
      query: (template) => ({
        url: `/message-templates`,
        method: "POST",
        body: template,
      }),
    }),
    adminUpdateProfessionalTemplates: builder.mutation({
      query: (template) => ({
        url: `/message-templates/${template.id}`,
        method: "POST",
        body: template,
      }),
    }),
    adminDeleteProfessionalTemplates: builder.mutation({
      query: (templateId) => ({
        url: `/message-templates/${templateId}`,
        method: "DELETE",
      }),
    }),
    adminFollowUpNotify: builder.mutation({
      query: (body) => ({
        url: `/followup-notification`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "matchesType", id: "get-matches" }],
    }),
  }),
});
//api/v2/followup-notification
// Export hooks for usage in functional components
export const {
    useAdminUpdateProfessionalTemplatesMutation,
    useAdminSaveProfessionalTemplatesMutation,
    useAdminDeleteProfessionalTemplatesMutation,
    useAdminFollowUpNotifyMutation,
} = adminTemplateCreation;
