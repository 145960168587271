import React, { useState, useRef } from "react";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { formatPhoneNumber } from "../../utilities/utils";

const CustomInputField = ({
  icon: Icon,
  value,
  onChange,
  placeHolder,
  type,
  errorMessage,
  name,
  classes,
  inputClasses,
  disabled = false,
  successIcon: SuccessIcon,
  onKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter" && onKeyDown) {
      onKeyDown();
    }
  };

  return (
    <div>
      <div className="w-full">
        <div className={`${classes}`}>
          {Icon && (
            <span className="pl-[14px] md:pl-[20px] pr-[10px] border-solid border-strokeColor border-r bg-transparent">
              <Icon className="icon w-6 h-6" />
            </span>
          )}
          {type === "password" && (
            <span
              className="pl-[14px] md:pl-[20px] pr-[10px] border-solid border-strokeColor border-r bg-transparent cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <LockOpenIcon className="icon w-6 h-6" />
              ) : (
                <LockClosedIcon className="icon w-6 h-6" />
              )}
            </span>
          )}
          <input
            id={name}
            name={name}
            type={type === "password" && showPassword ? "text" : type}
            value={name === "phone" ? formatPhoneNumber(value) : value}
            placeholder={placeHolder}
            className={`${inputClasses}`}
            onChange={onChange}
            disabled={disabled}
            onKeyDown={handleEnter}
            maxLength={
              name === "phone"
                ? 14
                : name === "creditCardNumber"
                ? 16
                : name === "zipCode" ||
                  name === "postal_code" ||
                  name === "billingZipCode"
                ? 5
                : name === "practice_since"
                ? 4
                : ""
            }
            onKeyPress={(event) => {
              if (
                name === "zipCode" ||
                name === "postal_code" ||
                name === "billingZipCode" ||
                name === "cell_phone_number" ||
                name === "office_phone_number" ||
                name === "phone" ||
                name === "practice_since"
              ) {
                const regex = /^[0-9]+$/;
                const key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              } else if (name === "city" ) {
                const regex = /^[a-zA-Z\s\.]+$/;
                const key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }else if (name === "title"){
                const regex = /^[a-zA-Z\s.,]+$/;
                const key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }
            }}
          />
          {SuccessIcon && (
            <span className="pl-[14px] md:pl-[10px] pr-[20px] border-solid border-[#CCD0D4] border-l bg-transparent">
              <SuccessIcon className="icon w-6 h-6 text-[#16CA7C]" />
            </span>
          )}
        </div>
        {errorMessage && (
          <p className="text-red-500 text-xs leading-[160%] font-bold mb-4 -mt-4 block">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomInputField;
