import React from "react";
import ProfessionalVirtualLeadDetail from "./ProfessionalVirtualLeadDetail";

const ProfessionalVirtualLead = ({
  professionalId,
  profession,
  states,
  otherStates,
  isSuccess,
  isDisabled,
}) => {
  const selectedClientLeadDetails = states?.map((state) => ({
    name: state.name,
    profession: state.profession_id,
    license: state.license_number,
    id: state.id,
  }));

  return (
    <div className="border border-solid border-[#EBEFF3] p-5 rounded-md mb-6 bg-white">
      <h5 className="text-base font-bold leading-[160%] text-customGray">
        Virtual Client Leads | {profession?.name} 
      </h5>
      {isDisabled&&<span className="text-xs md:text-sm font-medium leading-[160%] text-customGray">
           To update your state and license, please contact our customer success team:{" "}
          <a
            href="mailto:support@helpmatch.com"
            className="text-primary hover:underline"
          >
            mailto:support@helpmatch.com
          </a>
        </span>}
      <div>
        <ProfessionalVirtualLeadDetail
          professionalId={professionalId}
          states={states}
          otherStates={otherStates}
          profession={profession}
          selectedClientLeadDetails={selectedClientLeadDetails}
          isProfessionalSuccess={isSuccess}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default ProfessionalVirtualLead;
