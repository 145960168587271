import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useGetProfessionalInfoQuery } from "../../../../store/professionalInfo";
import ProfessionalVirtualLead from './ProfessionalVirtualLead';

const VirtualRegions = ({ isMobile }) => {
  const location = useLocation();
  const { professionalId } = location?.state ?? {};
const { data: professionalInfo, refetch, isSuccess } = useGetProfessionalInfoQuery()

    const professions = professionalInfo?.data?.professions
    ?.filter((data) => data?.parent_id === null)
    ?.map((item) => ({
      id: item.id,
      name: item.name,
      allowVirtualLead: item?.allow_virtual_leads,
    }));

    const getProfessionalStates = (id) => {
      const { states = [] } = professionalInfo?.data || {};
      
      return states.reduce(
        (acc, state) => {
          const formattedState = {
            profession_id: state.profession_id,
            license_number: state.license_number,
            state_id: state.id,
          };
    
          state.profession_id === id
            ? acc.professionStates.push(state)
            : acc.otherStates.push(formattedState);
    
          return acc;
        },
        { professionStates: [], otherStates: [] }
      );
    };

  return (
    <div>
      {!isMobile && (
        <h4 className="text-[22px] font-bold leading-[160%] text-customGray mb-6">
          Virtual Regions
        </h4>
      )}
        {(professions && professionalInfo) &&
          professions.map((profession, index) => {
            return (
              <ProfessionalVirtualLead
              key={index}
              professionalId={professionalId}
              profession={profession}
              states={
                getProfessionalStates(profession.id).professionStates ?? []
              }
              otherStates={
                getProfessionalStates(profession.id).otherStates ?? []
              }
              isProfessionalSuccess={isSuccess}
              isDisabled={true}
            />
            );
          })}
    </div>
  );
};

export default VirtualRegions;
