import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Element } from "react-scroll";
import useMeta from "../../hooks/useMeta";
import { getQuestionnairres } from "../../store/services/questions.service";
import { searchZipCode } from "../../utilities/landingPageUtils";
import { metaInfo } from "../../utilities/metaInfo";
import ProfileCarousel from "../ProfileCarousel/index";
import { profiles } from "../ProfileCarousel/profileData";

const VirtualLeadMatchHero = ({
  profession,
  skippedText,
  location,
  searchParam,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isEnabled, setIsEnabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  let professionName = profession?.name;
  if (professionName == "dental x rays") {
    professionName = "Dental X-rays";
  } else if (professionName === "dentist near me") {
    professionName = "Dentist";
  }
  const metaInformation = metaInfo("dentist", professionName);
  useMeta(metaInformation?.title ?? "", metaInformation?.description ?? "");

  const isMobile = useMemo(
    () =>
      window.matchMedia &&
      window.matchMedia("only screen and (max-width: 767px)").matches,
    []
  );

  const handleChange = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 1) {
      setSelectedOption(null);
      setSearchResults([]);
    }
    setSearchText(inputValue);
    if (inputValue.length > 4) {
      // setIsEnabled(false);
      const results = await searchZipCode(inputValue);
      setSelectedOption(results[0]);
      setSearchResults(results);
    } else {
      // setIsEnabled(true);
      setSelectedOption(null);
    }
  };

  const handleFindMatch = async () => {
    const questionnairesResult = await getQuestionnairres(profession?.id);
    navigate(
      `/${profession?.name
        ?.toLowerCase()
        .replace(/\s/g, "-")}/patient/services`,
      {
        state: {
          zipCode: selectedOption?.postalCode || selectedOption?.addressLabel,
          profession: profession?.name,
          questionnairesResult: questionnairesResult,
          professionId: profession?.id,
          originUrl: window.location.href,
        },
      }
    );
  };

  const formattedInputAddress = useMemo(() => {
    let formatAddress = "";
    if (selectedOption?.city) {
      formatAddress += selectedOption.city;
    }
    if (selectedOption?.state) {
      formatAddress += `, ${selectedOption.state}`;
    }
    return formatAddress;
  }, [selectedOption]);

  const getProfessionName = (name) => {
    let updatedName = name;
    if (professionName === "dentist near me") {
      updatedName = "Dentist";
    }
    return updatedName;
  };

  return (
    <Element name="find-profession">
      <section
        className="relative pt-[90px] md:pt-[120px] pb-[60px] md:pb-[100px] min-h-screen md:min-h-full flex md:block justify-center items-center overflow-hidden bg-[#F9FBFF]"
        id="landingpagebanner"
      >
        <div className="w-[295px] h-[295px] rounded-[296px] bg-[#DCEEFF] blur-[107px] absolute -left-[133px] top-[126px]"></div>
        <div className="w-[295px] h-[295px] rounded-[296px] bg-[#EBDFFB] blur-[107px] absolute -right-[163px] bottom-[161px]"></div>
        <div className="container mx-auto">
          <div className="max-w-[1056px] mx-auto w-full flex flex-col relative z-[1]">
            {/* <h1 className="landing-title">
              <span className="block text-lg md:text-2xl lg:text-3xl xl:text-[40px] font-normal md:mb-[15px]">
                Get Matched with a
              </span>{" "}
              Trusted and Affordable{" "}
              <span
                className={`text-dotColor1 ${
                  professionName == "Dental X-rays" ? " " : "capitalize"
                }`}
              >
                {" "}
                {professionName
                  ? getProfessionName(professionName)
                  : params?.professionName}
                {!isMobile && professionName === "Dentist" && <br />}
              </span>{" "}
              {!location ? "Near You" : `in ${location}`}
            </h1> */}
            <h1 className="landing-title max-w-full">
              <span className="block text-lg md:text-2xl lg:text-3xl xl:text-[40px] font-normal md:mb-[15px]">
              Connect with
              </span>{" "}
              Compassionate{" "}
              <span className="text-dotColor1">Mental Health Counselor</span>{" "}
              Professionals
            </h1>
            {/* <p
              className={`text-base md:text-[24px] xl:text-[28px] text-center font-normal leading-[140%] text-customGray mb-5 ${
                professionName !== "Dentist" ? "max-w-[860px]" : "max-w-[833px]"
              }  w-full mx-auto`}
            >
              Avoid Hours of Research & Book in Just Two Minutes:
            </p> */}
          </div>
          <div className="animate-scale-in">
            <ProfileCarousel profiles={profiles} />
          </div>
        </div>
      </section>
    </Element>
  );
};

export default VirtualLeadMatchHero;
