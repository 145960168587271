import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";

const PrivateRoute = ({ admin }) => {
  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    const isAuth = isAuthenticated || localStorage.getItem("token");
    if (!isAuthenticated && isAuth) {
      login();
    }
  }, [isAuthenticated, login]);

  const isAuth = isAuthenticated || localStorage.getItem("token");

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={admin ? "/admin-signin" : "/signin"} replace />
  );
};

export default PrivateRoute;
