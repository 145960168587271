import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { unAuthenticatedUser } from "../utilities/errorHandling";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const getProfessions = createApi({
  reducerPath: "professions",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + "api/v2",
    fetchFn: unAuthenticatedUser,
  }),
  tagTypes: ["professions"],
  endpoints: (builder) => ({
    getProfessions: builder.query({
      query: () => `/professions`,
      providesTags: [{ type: "professions", id: "professionList" }],
    }),
    getProfessionServices: builder.query({
      query: (id) => `/professions/${id}/services`,
      providesTags: [{ type: "professions", id: "professionListWithService" }],
    }),
    getProfessionsWithDivisions: builder.query({
      query: () => `/professions?withDivisions`,
      providesTags: [{ type: "professions", id: "professionListWithDivision" }],
    }),
    getProfessionsWithDivisionsAndServices: builder.query({
      query: () => `/professions?withDivisionsAndProfessionalServices`,
      providesTags: [
        { type: "professions", id: "professionListWithDivisionAndServices" },
      ],
    }),
    getStates: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: "/states",
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: [{ type: "professions", id: "professionList" }],
    }),

    getProfessionsWithServices: builder.query({
      query: () => `/professions?withServices`,
      providesTags: [{ type: "professions", id: "professionListWithServices" }],
    }),
    getProfessionsWithModalities: builder.query({
      query: () => `/professions?withModalities`,
      providesTags: [
        { type: "professions", id: "professionListWithModalities" },
      ],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetProfessionsQuery,
  useGetProfessionsWithDivisionsQuery,
  useGetProfessionsWithDivisionsAndServicesQuery,
  useGetProfessionServicesQuery,
  useLazyGetProfessionServicesQuery,
  useGetStatesQuery,
  useGetProfessionsWithModalitiesQuery,
  useGetProfessionsWithServicesQuery,
} = getProfessions;
